





















































































































































































































































































































































































import Vue from 'vue'
import { UserInterface } from '@/types/models/UserInterface'
import IsUserOnlineMixin from '@/mixins/IsUserOnline'
import formattingMixin from '@/mixins/formatting'
import UsersService from '@/services/users'
import SubscriptionType from '@/utils/enum/SubscriptionType'
import dataTableMixin from '@/mixins/dataTable'
import { UserPaymentHistoryInterface } from '@/types/models/UserPaymentHistoryInterface'
import { UserActivityHistoryInterface } from '@/types/models/UserActivityHistoryInterface'
import Interests from '@/utils/enum/Interests'
import AccountType from '@/utils/enum/AccountType'
import UserUpgradeState from '@/utils/enum/UserUpgradeState'
import { mapState } from 'vuex'
import { RootStateInterface } from '@/types/store/RootStateInterface'
import { UserReportInterface } from '@/types/models/UserReportInterface'

enum History {
  Activity,
  Report,
  Purchase
}

export default Vue.extend({
  name: 'UserDetails',
  mixins: [dataTableMixin, IsUserOnlineMixin, formattingMixin],
  props: {
    item: {
      type: Object as () => UserInterface,
      default: undefined
    }
  },
  data: () => ({
    History: History,
    expanded: [],
    verificationLoading: false,
    verificationLink: null,
    showAllInterests: false,
    history: History.Activity,
    tableLoading: false,
    tableActivityItems: [] as UserActivityHistoryInterface[],
    tableReportsItems: [] as UserReportInterface[],
    tablePurchaseItems: [] as UserPaymentHistoryInterface[],
    activityHistoryHeaders: [
      {
        text: 'Date',
        value: 'date',
        sortable: false,
        isMobile: true,
        mobileText: 'Date & Time'
      },
      {
        text: 'Time',
        value: 'time',
        sortable: false
      },
      {
        text: 'Post',
        value: 'entity_name',
        sortable: false,
        isMobile: true
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        isMobile: true
      }
    ],
    subscriptionType: SubscriptionType,
    interestTranslate: Interests,
    AccountType: AccountType,
    UserUpgradeState: UserUpgradeState,
    dialog: false,
    dialogImg: null as string | null
  }),
  computed: {
    ...mapState({
      isMobile: state => (state as RootStateInterface).mobile
    }),
    tableHeaders () {
      if (this.history === History.Activity) {
        return this.isMobile ? this.activityHistoryHeaders.filter(h => h.isMobile).map(h => {
          return {
            ...h,
            text: h.mobileText ? h.mobileText : h.text
          }
        }) : this.activityHistoryHeaders
      }
      if (this.history === History.Report) {
        return [
          { text: 'From', value: 'user.username', sortable: false },
          { text: 'Reason', value: 'reason', sortable: false, cellClass: 'report-reason' },
          { text: 'Screen', value: 'screen', sortable: false, cellClass: 'report-screenshot' },
          { text: 'Created', value: 'created', sortable: false },
          { text: 'Modified', value: 'modified', sortable: false },
          { text: 'Resolved', value: 'unread', sortable: false }
        ]
      }
      if (this.history === History.Purchase) {
        return [
          { text: 'Purchase date', value: 'date', sortable: false },
          { text: 'Purchase item', value: 'period', sortable: false },
          { text: 'Status', value: 'type', sortable: false }
        ]
      }
      return []
    },
    address () {
      if (!this.item.user_detail.address) {
        return
      }
      return JSON.parse(this.item.user_detail.address)
    }
  },
  methods: {
    getFlagPatch (flag: string): string {
      return require(`../assets/icons/flags/${flag}.svg`)
    },
    setActive (id: number) {
      this.$emit('set-active', id)
    },
    setInactive (id: number) {
      this.$emit('set-inactive', id)
    },
    changeVerification (item: UserInterface, approve: boolean) {
      this.$emit('change-verification', item, approve)
    },
    setLifetimePremium (id: number) {
      this.$emit('set-lifetime-premium', id)
    },
    setFreePlan (id: number) {
      this.$emit('set-free-plan', id)
    },
    openRemoveDialog (user: UserInterface) {
      this.$emit('open-remove-dialog', user)
    },
    getDataFromAPI () {
      this.tableLoading = true
      switch (this.history) {
        case History.Activity:
          UsersService.activityHistory(this.tableOptions, this.item.id).then(res => {
            if (res.data.body) {
              this.tableActivityItems = res.data.body.items ? res.data.body.items : []
              this.tableTotal = res.data.body.total ? res.data.body.total : 0
              this.pages = res.data.body.pages ? res.data.body.pages : 1
            }
          }).finally(() => {
            this.tableLoading = false
          })
          break
        case History.Report:
          UsersService.reportsHistory(this.tableOptions, this.item.id).then(res => {
            if (res.data.body) {
              this.tableReportsItems = res.data.body.items ? res.data.body.items : []
              this.tableTotal = res.data.body.total ? res.data.body.total : 0
              this.pages = res.data.body.pages ? res.data.body.pages : 1
            }
          }).finally(() => {
            this.tableLoading = false
          })
          break
        case History.Purchase:
          UsersService.paymentHistory(this.tableOptions, this.item.id).then(res => {
            if (res.data.body) {
              this.tablePurchaseItems = res.data.body.items ? res.data.body.items : []
              this.tableTotal = res.data.body.total ? res.data.body.total : 0
              this.pages = res.data.body.pages ? res.data.body.pages : 1
            }
          }).finally(() => {
            this.tableLoading = false
          })
          break
        default:
          this.tableLoading = false
      }
    },
    showImageModal (url: string) {
      this.dialogImg = url
      this.dialog = true
    },
    getVerificationLink () {
      if (!this.item) {
        return
      }
      this.verificationLoading = true
      UsersService.getVerificationLink(this.item.id)
        .then(res => {
          if (res.data.body && res.data.body.link) {
            this.verificationLink = res.data.body.link
          }
          this.verificationLoading = false
        })
        .catch(() => {
          this.verificationLoading = false
        })
    }
  },
  created () {
    this.getDataFromAPI()
  },
  watch: {
    item: {
      deep: true,
      handler (newV, oldV): void {
        if (newV && oldV && newV.id !== oldV.id) {
          this.tableOptions.page = 1
          this.getDataFromAPI()
        }
      }
    },
    history (newV, oldV) {
      if (newV !== oldV) {
        this.tableOptions.page = 1
        this.getDataFromAPI()
      }
    }
  }
})
